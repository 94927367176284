import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { signInWithEmailAndPassword } from 'firebase/auth';
import styles from './index.module.css'
import { auth } from '../../compoments/firebase';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";

type Inputs = {
    email: string
    password: string
}

const Login = () => {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);

    useEffect(() => {
        if (loading) {
          // trigger a loading screen
          return;
        }
        if (user) navigate("/doclist");
      }, [user, loading]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit: SubmitHandler<Inputs> = async(data) => {
        console.log(data)
        try {
            await signInWithEmailAndPassword(auth, data.email, data.password)
            console.log("Signed in successfully!")
            // Redirect to the document list page
            navigate('/doclist');

        } catch (error) {
            console.error("Error signing in: ", error)
        }
    }

    const handleSignUp = () => {
        // Redirect to the sign-up page
        navigate('/signup');
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <h2 className="text-2xl font-bold mb-6">Login</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-6 rounded shadow-md w-full max-w-sm">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Email:</label>
                    <input 
                        type="email"
                        {...register('email', { required: true })} 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                    />
                    {errors.email && <p>Email is required.</p>}
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Password:</label>
                    <input 
                        type="password"
                        {...register('password', { required: true })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" 
                    />
                    {errors.password && <p>Password is required.</p>}
                </div>
                <div className={styles.flex}>
                    <button 
                        type="submit" 
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >   
                        Login
                    </button>
                </div>
            </form>
            <button 
                onClick={handleSignUp} 
                className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
                Sign Up
            </button>
            <button 
                onClick={handleForgotPassword} 
                className="mt-2 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
                Forgot Password
            </button>
        </div>
    );
};

export default Login;