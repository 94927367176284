import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from '@firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAOMMZIpcXNZZmkxAA_o7aWMjzuXPorC_8",
  authDomain: "shipping-9b48c.firebaseapp.com",
  projectId: "shipping-9b48c",
  storageBucket: "shipping-9b48c.appspot.com",
  messagingSenderId: "283330494682",
  appId: "1:283330494682:web:2cba158ae3154cd1c065e3",
  measurementId: "G-B87G7HCYNZ"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
export const db = getFirestore(app);